import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Surveys from "../views/account/Surveys.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/session/Login.vue"),
  },
  {
    path: '/app-inside',
    name: 'app-inside',
    component: () => import("../views/RedirectAppStore.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: Main,
  },
  {
    path: "/surveys",
    name: "surveys",
    component: Surveys,
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/Error/Error.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/account/Main.vue"),
    redirect: { name: "profile-main" },
    children: [
      {
        path: "/",
        name: "profile-main",
        component: () => import("@/views/account/Profile.vue"),
      },
      // {
      //   path: "/address-profile",
      //   name: "address-profile",
      //   component: () => import("@/views/account/Address.vue"),
      // },
      {
        path: "/indications",
        name: "indications",
        component: () => import("@/views/account/Indications.vue"),
      },
      {
        path: "/payment",
        name: "payment",
        component: () => import("@/views/account/EmailPayment.vue"),
      },
      {
        path: "/leave",
        name: "leave",
        component: () => import("@/views/account/Leave.vue"),
      },
    ],
  },
  {
    path: "/retrieve-account",
    name: "retrieve",
    component: () => import("../views/session/EmailReset.vue"),
  },
  {
    path: "/resend-email",
    name: "resend-email",
    component: () => import("../views/session/ResendMail.vue"),
  },
  {
    path: "/reset/:token",
    name: "reset-pass",
    component: () => import("../views/session/PassReset.vue"),
  },
  {
    path: "/inactive_user/",
    name: "InactiveUser",
    component: () => import("../views/InactiveUser.vue"),
  },
  {
    path: "/register",
    component: () => import("../views/session/register/Main.vue"),
    children: [
      {
        path: "/",
        name: "auth-data",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/session/register/AuthData"),
      },
      {
        path: "/sign-up-conf/:token",
        name: "user-data",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/session/register/UserData"),
      },

      {
        path: "/address/",
        name: "address",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/session/register/AddressData"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

 const NotNeededAuthenticationRoutes = [
  "app-inside"
];  


router.beforeEach(async (to, from, next) => {
  document.title = "Inside Brasil";

  if (NotNeededAuthenticationRoutes.includes(to.name)) next();
  else next({ name: "app-inside" }); 
});



export default router;
